import $ from 'jquery';
import jQuery from 'jquery';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';

import './scss/app.scss';

window.$ = $;
window.jQuery = jQuery;

$(document).ready(function(){

	// Referenties slideshow
	function startSlickslider(){
		console.log('slick slider');
		$('.referenties__container').slick({
			slidesToShow: 6,
			responsive: [
			    {
			      breakpoint: 1024,
			      settings: {
			        slidesToShow: 4,
			        slidesToScroll: 1,
			        infinite: true,
			      }
			    },
			    {
			      breakpoint: 600,
			      settings: {
			        slidesToShow: 2,
			        slidesToScroll: 2
			      }
			    },
			    {
			      breakpoint: 480,
			      settings: {
			        slidesToShow: 2,
			        slidesToScroll: 1
			      }
			    }
			    // You can unslick at a given breakpoint now by adding:
			    // settings: "unslick"
			    // instead of a settings object
			  ]			
		});
	}

	// ScrollSpy
	function startScrollSpy(){

	    // Smooth Scroll ScrollSpy
	    $(".list-group a[href^='#']").on('click', function(e) {
	       e.preventDefault();

	       var hash = this.hash;

	       $('html, body').animate({
	           scrollTop: $(hash).offset().top
	         }, 300, function(){

	           window.location.hash = hash;
	         });
	    });
	}

	// Fire ze functions
	startSlickslider();
	startScrollSpy();
});